
import {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import StationDetailList from '@common/components/stationDetails/StationDetailList.vue'
import StationDetailReachability from '@common/components/stationDetails/StationDetailReachability.vue'
import StationDetailInfrastructure from '@common/components/stationDetails/StationDetailInfrastructure.vue'
import StationDetailFasta from '@common/components/stationDetails/StationDetailFasta.vue'
import StationDetailSCentral from '@common/components/stationDetails/StationDetailSCentral.vue'
import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import OpeningHours from '@common/components/misc/OpeningHours.vue'

export type StationInfos = {
    details?: Stada.Station | null
    central?: Stada.SZentrale | null
    facilities?: Fasta.Facility[] | null
}

type Props = {
    stationInfos: StationInfos
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetails',
    functional: true,
    props: {
        stationInfos: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {details, central, facilities} = props.stationInfos

        if (!details) {
            return createElement(
                'p',
                mergeData(data, {staticClass: 'no-details-hint'}),
                'Zu dieser Station oder Haltestelle liegen leider keine Bahnhofsinformationen vor.',
            )
        }
        const childElements = [
            createElement(StationDetailList, {props: {details}}),
            createElement(StationDetailReachability, {props: {details}}),
            createElement(StationDetailInfrastructure, {props: {details}}),
            createElement(StationDetailSCentral, {props: {central, open: true}}),
        ]

        function addOpeningHours(title: string, schedule?: Stada.Schedule) {
            if (!schedule) {
                return
            }
            childElements.push(
                createElement(DbiAccordion, {scopedSlots: {summary: () => title}}, [
                    createElement(OpeningHours, {props: {days: schedule.availability}}),
                ]),
            )
        }

        addOpeningHours('DB Information', details.DBinformation)
        addOpeningHours('Lokales Servicepersonal', details.localServiceStaff)

        if (facilities && facilities.length) {
            childElements.push(createElement(StationDetailFasta, {props: {facilities, open: true}}))
        }

        return createElement('div', mergeData(data, {staticClass: 'station-details'}), childElements)
    },
}
export default component


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import LinkAddress from '@common/components/misc/LinkAddress.vue'
import LinkPhone from '@common/components/misc/LinkPhone.vue'
import DefinitionList, {DefinitionListItem} from '@common/components/misc/DefinitionList.vue'
import {renderNameNumber} from '@common/filters'

type Props = {
    central: Stada.SZentrale
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetailSCentral',
    functional: true,
    props: {
        central: {
            type: Object,
        },
    },
    render(createElement, {data, props}) {
        const {central} = props

        if (!central) {
            return []
        }

        const {number, address, publicPhoneNumber, internalPhoneNumber, publicFaxNumber, internalFaxNumber} = central

        const items: DefinitionListItem[] = [
            ['Name', renderNameNumber(central)],
            ['Adresse', [createElement(LinkAddress, {props: {address}})]],
            ['Telefon (öffentlich)', [createElement(LinkPhone, {props: {number: publicPhoneNumber}})]],
            ['Telefon (intern)', internalPhoneNumber],
            ['Fax (öffentlich)', publicFaxNumber],
            ['Fax (intern)', internalFaxNumber],
        ]

        return createElement(
            DbiAccordion,
            mergeData(data, {
                staticClass: 'station-3s-central',
                key: `station-3s-central-${number}`,
                scopedSlots: {summary: () => '3-S-Zentrale'},
            }),
            [createElement(DefinitionList, {props: {items}})],
        )
    },
}
export default component

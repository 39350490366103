
import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

type Props = {
    number: string
}
const component: FunctionalComponentOptions<Props> = {
    name: 'PhoneLink',
    functional: true,
    props: {
        number: {
            type: String,
            required: true,
        },
    },
    render(createElement, {data, props, slots}) {
        const defaultSlot = slots().default
        const {number} = props
        const childElement = defaultSlot || number

        return createElement(
            'a',
            mergeData(data, {staticClass: 'phone-link', attrs: {href: `tel:${number}`}}),
            childElement,
        )
    },
}
export default component


import type {FunctionalComponentOptions, VNodeData} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import {ensureArray} from '@common/lib'

type AccordionProps = {
    open: boolean
}
const component: FunctionalComponentOptions<AccordionProps> = {
    name: 'DbiAccordion',
    functional: true,
    props: {
        open: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, {data, slots, props, listeners}) {
        const {open} = props
        const componentData = {
            staticClass: 'accordion',
            attrs: {open},
        }

        const summaryData: VNodeData = {}
        const openListener = listeners['update:open']
        if (openListener) {
            summaryData.on = {
                click: (event: MouseEvent) => {
                    event.preventDefault()
                    ensureArray(openListener).forEach((fn) => fn(!open))
                },
            }
        }

        // TODO:
        // not having to use a scoped slot would be great (problem consist in functional components using this component)
        const summarySlot = data.scopedSlots?.summary
        const summary = summarySlot ? [summarySlot(null)] : ''

        return createElement('details', mergeData(data, componentData), [
            createElement('summary', summaryData, [
                createElement('h2', {staticClass: 'summary-text'}, summary),
                createElement(SvgSprite, {
                    props: {icon: 'chevron-down'},
                    staticClass: 'toggle-icon',
                }),
            ]),
            createElement('div', {staticClass: 'details-content'}, slots().default),
        ])
    },
}
export default component


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import IconList from '@common/components/misc/IconList.vue'

type Props = {
    details: Stada.Station
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetailReachability',
    functional: true,
    props: {
        details: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {
            number,
            hasParking,
            hasBicycleParking,
            hasLocalPublicTransport,
            hasTaxiRank,
            hasCarRental,
        } = props.details

        const icons = [
            ['opnv', 'ÖPNV', hasLocalPublicTransport],
            ['taxi', 'Taxistand', hasTaxiRank],
            ['car-parking', 'Parkplatz', hasParking],
            ['car-rental', 'Mietwagen', hasCarRental],
            ['bicycle', 'Fahrradstellplatz', hasBicycleParking],
        ]

        return createElement(
            DbiAccordion,
            mergeData(data, {
                staticClass: 'station-reachability',
                key: `station-reachability-${number}`,
                scopedSlots: {summary: () => 'Erreichbarkeit'},
            }),
            [createElement(IconList, {props: {icons}})],
        )
    },
}
export default component


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import {getDayOfWeek} from '@common/lib'
import {VNode} from 'vue'

const dayMap = [
    ['monday', 'Montag'],
    ['tuesday', 'Dienstag'],
    ['wednesday', 'Mittwoch'],
    ['thursday', 'Donnerstag'],
    ['friday', 'Freitag'],
    ['saturday', 'Samstag'],
    ['sunday', 'Sonntag'],
    ['holiday', 'Feiertag'],
] as const

type Props = {
    days: Stada.Schedule['availability']
}
const component: FunctionalComponentOptions<Props> = {
    name: 'OpeningHours',
    functional: true,
    props: {
        days: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {days} = props
        const childElements: VNode[] = []
        const today = getDayOfWeek()

        dayMap.forEach(([key, name], index) => {
            const {fromTime, toTime} = days[key]
            const clazz = {
                today: index === today,
            }
            childElements.push(createElement('dt', {class: clazz}, name))
            childElements.push(createElement('dd', {class: clazz}, `${fromTime} - ${toTime} Uhr`))
        })

        return createElement('dl', mergeData(data, {staticClass: 'opening-hours'}), childElements)
    },
}
export default component

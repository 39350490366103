
import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'

export type IconListConfig = readonly [string, string, boolean][]

type Props = {
    icons: IconListConfig
}
const component: FunctionalComponentOptions<Props> = {
    name: 'IconList',
    functional: true,
    props: {
        icons: {
            type: Array,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const childElements = props.icons.map(([icon, title, available]) => {
            return createElement(
                'div',
                {
                    class: {'un-available': !available},
                    staticClass: 'icon-list-item',
                },
                [
                    createElement(SvgSprite, {props: {icon, title}}),
                    createElement('span', {domProps: {innerHTML: title}}),
                ],
            )
        })

        return createElement('div', mergeData(data, {staticClass: 'icon-list'}), childElements)
    },
}
export default component

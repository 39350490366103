





















import {Component, Vue, Watch} from 'vue-property-decorator'

import StationSelect from '@/components/form/StationSelect.vue'
import DbiLoader from '@common/components/ui/DbiLoader.vue'
import StationDetails, {StationInfos} from '@common/components/stationDetails/StationDetails.vue'

import {TRANSPORT_TYPES_TRAINS} from '@common/const'

import {getStationDetails, get3sCentral, getFacilityStates} from '@common/api'

@Component({
    name: 'StationDetailsView',
    components: {
        StationSelect,
        DbiLoader,
        StationDetails,
    },
})
export default class StationDetailsView extends Vue {
    filterProducts = TRANSPORT_TYPES_TRAINS
    showSelect = false
    buttons = {
        left: {
            icon: 'filter',
            title: 'Auswahl Bahnhof',
            onClick: this.openStationSelect.bind(this),
        },
    }

    loading = false
    stationInfos: StationInfos = {}

    get station(): Dbi.Station {
        return this.$dbiStore.state.detailedStation
    }

    set station(station: Dbi.Station) {
        this.$dbiStore.actions.detailedStation(station)
        this.showSelect = false
    }

    openStationSelect(): void {
        this.showSelect = true
    }

    closeStationSelect(): void {
        this.showSelect = false
    }

    @Watch('station', {immediate: true})
    async update({eva}: Dbi.Station): Promise<void> {
        this.loading = true
        this.stationInfos = {}

        try {
            const details = await getStationDetails(eva)
            if (details) {
                this.stationInfos.details = details
                const {number, szentrale} = details
                const results = await Promise.all([get3sCentral(szentrale.number), getFacilityStates(number)])
                this.stationInfos.central = results[0] as StationInfos['central']
                this.stationInfos.facilities = results[1] as StationInfos['facilities']
            }
        }
        catch (error) {
            this.$log.error(error)
        }
        finally {
            this.loading = false
        }
    }

    public handleBottomBarClick(): void {
        if (window.scrollY !== 0) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
        else {
            this.update(this.station)
        }
    }
}


import type {FunctionalComponentOptions, VNode, VNodeChildren} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

export type DefinitionListItem = readonly [VNodeChildren, VNodeChildren | number | {toString: () => string}]

type Props = {
    items: DefinitionListItem[]
}
const component: FunctionalComponentOptions<Props> = {
    name: 'DefinitionList',
    functional: true,
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const childElements: VNode[] = []
        props.items.forEach(([term, value]) => {
            childElements.push(createElement('dt', term))
            childElements.push(createElement('dd', (value as unknown) as VNodeChildren))
        })

        return createElement('dl', mergeData(data, {staticClass: 'definition-list'}), childElements)
    },
}
export default component

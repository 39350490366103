
import type {FunctionalComponentOptions, VNode, VNodeData} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import {ensureArray} from '@common/lib'

const PAGES_TO_DISPLAY = 5
const PAGES_TO_DISPLAY_HALF = Math.floor(PAGES_TO_DISPLAY / 2)

type Props = {
    curPage: number
    totalPages: number
}
const component: FunctionalComponentOptions<Props> = {
    name: 'DbiPagination',
    functional: true,
    props: {
        curPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
    },
    render(createElement, {data, props, listeners}) {
        const {curPage, totalPages} = props

        if (totalPages < 2) {
            return []
        }

        const pageListener = ensureArray(listeners['update:curPage'])

        function renderButton(page: number, extra: VNodeData = {}, label?: string | VNode[]): VNode {
            label ||= page.toString()
            const currentPage = page === curPage
            const inactive = page < 1 || page > totalPages
            const classes = {
                'current-page': currentPage,
                inactive,
            }
            const on: VNodeData['on'] = {}
            if (pageListener) {
                on.click = () => pageListener.forEach((fn) => fn(page))
            }
            const childElement =
                currentPage || inactive
                    ? label
                    : [createElement('button', {staticClass: 'pagination-button', on}, label)]
            return createElement('li', mergeData(extra, {class: classes}), childElement)
        }

        const childElements = [
            renderButton(curPage - 1, {key: 'pagination-previous', staticClass: 'previous'}, [
                createElement(SvgSprite, {props: {icon: 'chevron-left', title: 'vorherige'}}),
            ]),
        ]

        let startPage = Math.max(1, curPage - PAGES_TO_DISPLAY_HALF)
        let endPage = Math.min(totalPages, curPage + PAGES_TO_DISPLAY_HALF)

        const missingPages = PAGES_TO_DISPLAY - endPage + startPage
        if (missingPages > 0) {
            if (curPage - startPage < endPage - curPage) {
                endPage = Math.min(totalPages, curPage + missingPages)
            }
            else {
                startPage = Math.max(1, curPage - missingPages)
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            childElements.push(renderButton(page))
        }

        childElements.push(
            renderButton(curPage + 1, {key: 'pagination-next', staticClass: 'next'}, [
                createElement(SvgSprite, {props: {icon: 'chevron-right', title: 'nächste'}}),
            ]),
        )

        return createElement(
            'nav',
            mergeData(data, {
                staticClass: 'pagination',
                attrs: {role: 'navigation', ariaLabel: 'Pagination'},
            }),
            [createElement('ul', {staticClass: 'pagination-items'}, childElements)],
        )
    },
}
export default component


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import IconList from '@common/components/misc/IconList.vue'

type Props = {
    details: Stada.Station
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetailInfrastructure',
    functional: true,
    props: {
        details: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {
            number,
            hasPublicFacilities,
            hasLockerSystem,
            hasTravelNecessities,
            hasWiFi,
            hasTravelCenter,
            hasRailwayMission,
            hasDBLounge,
            hasLostAndFound,
            DBinformation,
        } = props.details

        const dbServices = [
            ['db-reisezentrum', 'DB Reisezentrum', hasTravelCenter],
            ['db-info', 'DB Information', !!DBinformation],
            ['db-lounge', 'DB Lounge', hasDBLounge],
        ]

        const stationServices = [
            ['wc', 'WC', hasPublicFacilities],
            ['shopping-cart', 'Reisebedarf', hasTravelNecessities],
            ['locker-system', 'Schließfächer', hasLockerSystem],
            ['wifi', 'W-Lan', hasWiFi],
            ['bahnhofs-mission', 'Bahnhofsmission', hasRailwayMission],
            ['lost-and-found', 'Fund&shy;service', hasLostAndFound],
        ]

        return createElement(
            DbiAccordion,
            mergeData(data, {
                staticClass: 'station-infrastructure',
                key: `station-infrastructure-${number}`,
                scopedSlots: {summary: () => 'Ausstattung'},
            }),
            [
                createElement(IconList, {props: {icons: dbServices}}),
                createElement(IconList, {props: {icons: stationServices}}),
            ],
        )
    },
}
export default component


import {Component, Vue, Prop, Watch} from 'vue-property-decorator'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import DbiPagination from '@common/components/misc/DbiPagination.vue'

import {compareASC, compareDESC} from '@common/lib'
import type {CreateElement, VNode} from 'vue/types'

export type SortableTableOptions<ItemType extends Record<string, string | number>> = {
    header: [keyof ItemType, string][]
    itemsPerPage?: number
}

const DEFAULT_OPTIONS = {
    itemsPerPage: 10,
}

@Component({
    name: 'SortableTable',
    components: {
        DbiPagination,
    },
})
export default class SortableTable<ItemType extends Record<string, string | number>> extends Vue {
    @Prop({type: Object, required: true})
    readonly options!: SortableTableOptions<ItemType>

    @Prop({type: Array, required: true})
    readonly items!: ItemType[]

    internalItems: ItemType[] = []

    sortingKey = 'type' as keyof ItemType
    sortASC = true

    curPage = 1
    totalPages = 1

    get innerOptions(): Required<SortableTableOptions<ItemType>> {
        return Object.assign({}, DEFAULT_OPTIONS, this.options)
    }

    get itemPage(): ItemType[] {
        const {itemsPerPage} = this.innerOptions
        const start = (this.curPage - 1) * itemsPerPage
        return this.internalItems.slice(start, start + itemsPerPage)
    }

    @Watch('items', {immediate: true})
    setInternalItems(items: ItemType[]): void {
        const {itemsPerPage} = this.innerOptions
        this.internalItems = items.slice()
        this.toggleSorting(this.sortingKey)
        this.curPage = 1
        this.totalPages = Math.ceil(items.length / itemsPerPage)
    }

    toggleSorting(key: keyof ItemType): void {
        if (this.sortingKey !== key) {
            this.sortingKey = key
            this.sortASC = true
        }
        else {
            this.sortASC = !this.sortASC
        }

        this.curPage = 1

        const sortFn = this.sortASC ? compareASC : compareDESC
        this.internalItems.sort((a, b) => sortFn(a[key], b[key]))
    }

    render(createElement: CreateElement): VNode {
        const {
            sortingKey,
            sortASC,
            itemPage,
            $scopedSlots,
            curPage,
            totalPages,
            options: {header},
        } = this
        const sortIndicator = createElement(
            'span',
            {staticClass: 'sort-indicator-wrapper', key: 'sort-indicator-wrapper'},
            [
                createElement(SvgSprite, {
                    staticClass: 'sort-indicator sort-indicator-up',
                    props: {icon: 'chevron-up'},
                }),
                createElement(SvgSprite, {
                    staticClass: 'sort-indicator sort-indicator-down',
                    props: {icon: 'chevron-down'},
                }),
            ],
        )

        const head = createElement('thead', [
            createElement(
                'tr',
                header.map(([key, title]) => {
                    const clazz = []
                    if (sortingKey === key) {
                        clazz.push(`sorted-${sortASC ? 'up' : 'down'}`)
                    }
                    const innerKey = `th-${key}`
                    return createElement('th', {staticClass: innerKey, key: innerKey}, [
                        createElement(
                            'button',
                            {
                                class: clazz,
                                on: {click: () => this.toggleSorting(key)},
                            },
                            [sortIndicator, title],
                        ),
                    ])
                }),
            ),
        ])

        const defaultSlot = $scopedSlots.default
        const body = defaultSlot ? createElement('tbody', [itemPage.map((item) => defaultSlot({item}))]) : []

        return createElement('div', {staticClass: 'sortable-table'}, [
            createElement(DbiPagination, {
                staticClass: 'top-pagination',
                props: {curPage, totalPages},
                on: {
                    'update:curPage': (value: number) => {
                        this.curPage = value
                    },
                },
            }),
            createElement('table', [head, body]),
            createElement(DbiPagination, {
                staticClass: 'bottom-pagination',
                props: {curPage, totalPages},
                on: {
                    'update:curPage': (value: number) => {
                        this.curPage = value
                    },
                },
            }),
        ])
    }
}


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import LinkAddress from '@common/components/misc/LinkAddress.vue'
import LinkPhone from '@common/components/misc/LinkPhone.vue'
import DefinitionList, {DefinitionListItem} from '@common/components/misc/DefinitionList.vue'
import {renderNameNumber} from '@common/filters'

const MOBILITY_SERVICE_NUMBER = '01806 512 512'

type Props = {
    details: Stada.Station
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetailList',
    functional: true,
    props: {
        details: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {
            name,
            number,
            mailingAddress: address,
            stationManagement,
            regionalbereich,
            ril100Identifiers,
            evaNumbers,
            federalState,
            aufgabentraeger,
            timeTableOffice,
            category,
            priceCategory,
            // services
            hasMobilityService,
            hasSteplessAccess,
        } = props.details

        const stepless = (() => {
            switch (hasSteplessAccess) {
                case 'yes':
                    return 'Ja'
                case 'partial':
                    return 'Teilweise'
                default:
                    return 'Nein'
            }
        })()

        // possible Values:
        // "no"
        // "Nur nach Voranmeldung unter 01806 512 512"
        // "Ja, um Voranmeldung unter 01806 512 512 wird gebeten"
        const mobilityService = (() => {
            if (hasMobilityService === 'no') {
                return 'Nein'
            }
            const phoneNumber = createElement(LinkPhone, {props: {number: MOBILITY_SERVICE_NUMBER}})
            const [before, after] = hasMobilityService.split(MOBILITY_SERVICE_NUMBER)
            return [before, phoneNumber, after]
        })()

        const items: DefinitionListItem[] = [
            ['Name', name],
            ['Adresse', [createElement(LinkAddress, {props: {address}})]],
            ['BM', renderNameNumber(stationManagement)],
            ['Regionalbereich', renderNameNumber(regionalbereich)],
            ['Stationcode', number],
            ['Ril100-Codes', ril100Identifiers.map(({rilIdentifier}) => rilIdentifier).join(', ')],
            ['EVA-Nummern', evaNumbers.map(({number}) => number).join(', ')],
            ['Bundesland', federalState],
            ['Aufgabenträger', `${aufgabentraeger.name} (${aufgabentraeger.shortName})`],
            [
                'Fahrplanbüro',
                [createElement('a', {attrs: {href: `mailto:${timeTableOffice.email}`}}, timeTableOffice.name)],
            ],
            ['Kategorie', category],
            ['Preiskategorie', priceCategory],
            // services
            ['Stufenfreier Zugang', stepless],
            ['Mobilitätsservice', mobilityService],
        ]

        return createElement(
            DbiAccordion,
            mergeData(data, {scopedSlots: {summary: () => 'Allgemeine Informationen'}}),
            [createElement(DefinitionList, {props: {items}})],
        )
    },
}
export default component


import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

export type Address = {
    street: string
    zipcode: number
    city: string
}

type Props = {
    address: Address
}
const component: FunctionalComponentOptions<Props> = {
    name: 'LinkAddress',
    functional: true,
    props: {
        address: {
            type: Object,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {
            address: {street, zipcode, city},
        } = props
        const query = encodeURI(`${street}, ${zipcode} ${city}`)

        return createElement(
            'a',
            mergeData(data, {
                attrs: {
                    href: `https://www.google.com/maps/search/?api=1&query=${query}`,
                    target: '_blank',
                },
            }),
            [street, createElement('br'), `${zipcode} ${city}`],
        )
    },
}
export default component

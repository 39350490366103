
import type {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import DbiAccordion from '@common/components/misc/DbiAccordion.vue'
import SortableTable from '@common/components/misc/SortableTable.vue'

const FASTA_TABLE_OPTIONS = {
    header: [
        ['type', 'Typ'],
        ['description', 'Beschreibung'],
        ['equipmentnumber', 'EQ'],
        ['state', 'Status'],
    ],
    itemsPerPage: 20,
}

const TYPE_MAP = {
    ESCALATOR: 'Rolltreppe',
    ELEVATOR: 'Aufzug',
}

function getState(state: Fasta.Facility['state']): string {
    switch (state) {
        case 'ACTIVE':
            return 'aktiv'
        case 'INACTIVE':
            return 'inaktiv'
        default:
            return 'keine Info'
    }
}

type Props = {
    facilities: Fasta.Facility[]
}
const component: FunctionalComponentOptions<Props> = {
    name: 'StationDetailFastaRow',
    functional: true,
    props: {
        facilities: {
            type: Array,
            required: true,
        },
    },
    render(createElement, {data, props}) {
        const {facilities} = props

        function itemSlot({item}: {item: Fasta.Facility}): VNode {
            const {equipmentnumber, type, description, state} = item
            return createElement(
                'tr',
                {
                    key: `fasta-item-${equipmentnumber}`,
                    staticClass: `fasta-item fasta-state-${state.toLowerCase()}`,
                },
                [
                    createElement('td', {staticClass: 'fasta-type'}, TYPE_MAP[type]),
                    createElement('td', {staticClass: 'fasta-description'}, description),
                    createElement('td', equipmentnumber.toString()),
                    createElement('td', {staticClass: 'fasta-state'}, getState(state)),
                ],
            )
        }

        let worstState = 'ACTIVE'
        for (const {state} of facilities) {
            if (state === 'INACTIVE') {
                worstState = state
                break
            }
            else if (state === 'UNKNOWN') {
                worstState = state
            }
        }

        const title = createElement(
            'span',
            {
                staticClass: 'fasta-title',
                class: `fasta-title-state-${worstState.toLowerCase()}`,
            },
            'Fördertechnische Anlagen (FTA)',
        )

        return createElement(
            DbiAccordion,
            mergeData(data, {
                staticClass: 'fasta',
                scopedSlots: {summary: () => title},
            }),
            [
                createElement(SortableTable, {
                    staticClass: 'fasta-list',
                    props: {options: FASTA_TABLE_OPTIONS, items: facilities},
                    scopedSlots: {default: itemSlot},
                }),
            ],
        )
    },
}
export default component
